import React from "react";
import { Link } from "gatsby"

import {
  Root,
  BgImage,
  Container,
  Title,
  BodyWrapper,
  Divider,
  FormWrapper,
  FormDesc,
  Desc,
  Signature,
  Button,
  // Heading,
  // HeadingImg,
  // HeadingText,
} from "./HeroTheLegend.style";

import BgDesktopSrc from '../../images/hero-main-bg-desktop2.jpg';
import SignatureSrc from "../../images/signature.svg";
// import LogoMinSrc from "../../images/logo-min.svg";

import SubscribeForm from "../SubscribeForm/SubscribeForm";

const HeroTheLegend = ({ title, isNotFound, withForm }) => {
  return (
    <Root>
      <BgImage>
        <img src={BgDesktopSrc} alt="" />
      </BgImage>
      <Container>
        <Title>{title}</Title>

        <BodyWrapper>
          <Divider />
          {withForm && (
            <FormWrapper>
              <FormDesc>We’ll be opening soon. In the meantime sign up to be notified first.</FormDesc>
              <SubscribeForm placeholder="Email" submitText="Submit" isShop />
            </FormWrapper>
          )}
        </BodyWrapper>

        {isNotFound ? (
          <Button as={Link} to="/#product">Go back home</Button>
        ) : (
          <>
            <Desc>
              Under moonlight, over mountains,
              <br />
              By the dancing light of stars,
              <br />
              Lived a man by the name of Ownby,
              <br />
              Who raised a glass and raised the bar.
            </Desc>
            <Signature src={SignatureSrc} alt="" />
          </>
        )}

        {/* <Heading>
          <HeadingImg src={LogoMinSrc} alt="" />
          <HeadingText>our&nbsp;product</HeadingText>
        </Heading> */}
      </Container>
    </Root>
  );
};

export default HeroTheLegend;
